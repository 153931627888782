import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import PlaceholderImage from "../assets/placeholder.svg";
import CourseOutline from "./CourseOutline";
import ProgressBar from "./ProgressBar";

function CourseContent() {
  const { id, chapterId, lessonId } = useParams(); // Get course ID, chapter ID, and lesson ID from URL
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState("chapter"); // "chapter" or "lesson"
  const [currentLesson, setCurrentLesson] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the course data based on the ID
    fetch(`/wp-content/plugins/newlearn-lms/frontend/build/courses.json`)
      .then((response) => response.json())
      .then((data) => {
        const courseData = data.data.find((course) => course.id === id);
        setCourse(courseData);
        setLoading(false);

        // If lessonId is present in the URL, set the view to "lesson"
        if (lessonId) {
          const lesson = courseData.chapters
            .flatMap((chapter) => chapter.lessons)
            .find((lesson) => lesson.id === lessonId);
          setCurrentLesson(lesson);
          setView("lesson");
        } else if (chapterId) {
          setView("chapter");
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        setLoading(false);
      });
  }, [id, chapterId, lessonId]);

  // Mimic progress bar loading state.
  const [progress] = useState(25);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!course) {
    return <div>Course not found</div>;
  }

  const handleNextClick = () => {
    if (view === "chapter") {
      const firstLesson = course.chapters.find(
        (chapter) => chapter.id === chapterId
      ).lessons[0];
      setCurrentLesson(firstLesson);
      setView("lesson");
      navigate(`/course/${id}/chapter/${chapterId}/lesson/${firstLesson.id}`);
    } else {
      // Handle next lesson logic here
      // TO-DO: If the current lesson is the last lesson in the chapter,
      // navigate to the next chapter else add disabled attribute to the button
    }
  };

  const handlePreviousClick = () => {
    if (view === "lesson") {
      setView("chapter");
      navigate(`/course/${id}/chapter/${chapterId}`);
    } else {
      // Handle previous chapter logic here
    }
  };

  return (
    <>
      <ProgressBar progress={progress} />
      <Container className="p-0 my-5" fluid>
        <Row>
          <Col md={4}>
            <CourseOutline courseId={id} />
          </Col>
          <Col md={8}>
            {view === "chapter" ? (
              <>
                <h1 className="fw-bold mb-3">{course.title}</h1>
                <img
                  src={course.image_url || PlaceholderImage}
                  alt={course.title}
                  className="img-fluid rounded-4 w-100 h-50 object-fit-cover"
                />
                <p className="mt-4 fs-lg">{course.description}</p>
              </>
            ) : (
              <>
                <h1 className="fw-bold mb-3">{currentLesson.title}</h1>
                <p className="mt-4 fs-lg">{currentLesson.description}</p>
                <Form className="bg-primary-light rounded-4 p-4 fs-lg">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="fw-bold">Vraag</Form.Label>
                    <p>Vul in wat je hierover denkt.</p>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Jouw antwoord..."
                    />
                  </Form.Group>
                </Form>
              </>
            )}

            <div className="d-flex justify-content-between mt-5">
              <Link
                to={`/course/${course.id}`}
                className="btn btn-outline-secondary text-body"
              >
                Terug naar begin
              </Link>
              <div>
                <button
                  className="btn btn-secondary"
                  onClick={handlePreviousClick}
                  disabled={view === "chapter"}
                >
                  Vorige
                </button>
                <button
                  className="btn btn-secondary ms-3"
                  onClick={handleNextClick}
                >
                  Volgende
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CourseContent;
