import React from "react";

import { ProgressBar, Container, Row, Col } from "react-bootstrap";

function Progressbar({ progress }) {
  return (
    <div className="py-3 bg-tertiary-light mt-0">
      <Container className="p-0" fluid>
        <Row className="justify-content-center">
          <Col md={12} lg={9}>
            <div className="d-flex align-items-center justify-content-between ">
              <ProgressBar className="bg-white flex-fill rounded-pill" variant="primary" now={progress} />
              <div className="ms-3 text-secondary-dark">
                <span className="fw-bold text-primary">25%</span>
                <span className="mx-3">
                  <strong>2</strong> / 10 stappen
                </span>
                <span>
                  <strong>39 min.</strong> actief
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Progressbar;
