import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import PlaceholderImage from "../assets/placeholder.svg";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import LoadingPlaceholder from "./LoadingPlaceholder";
import PaginationCustom from "./Pagination";

function CourseList() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  // Fetch courses data
  useEffect(() => {
    setTimeout(() => {
      fetch("/wp-content/plugins/newlearn-lms/frontend/build/courses.json")
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            setCourses(data.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
          setLoading(false);
        });
    }, 800);
  }, []);

  // Get unique categories from courses
  const categories = Array.from(
    new Set(
      courses.flatMap((course) =>
        course.categories.map((category) => category.title)
      )
    )
  );

  // Update selected category based on URL query parameter
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get("q");
    setSelectedCategory(category || "");
  }, [location.search]);

  // Filter courses based on selected category
  const filteredCourses = selectedCategory
    ? courses.filter((course) =>
        course.categories.some(
          (category) => category.title === selectedCategory
        )
      )
    : courses;

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
    navigate(`?q=${category}`);
  };

  return (
    <Container className="p-0" fluid>
      <Row className="justify-content-center">
        <h1 className="text-center mt-5 mb-3">Cursussen</h1>
        <Col md={4}>
          <Form.Group controlId="categoryFilter" className="mb-5">
            <Form.Select
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="" disabled>
                Filter op thema...
              </option>
              <option value="">Alle thema's</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row xs={1} md={2} lg={3} className="gy-4">
        {loading ? (
          <LoadingPlaceholder />
        ) : (
          filteredCourses.map((course) => (
            <Col md key={course.id}>
              <Card className="courses shadow border-0 h-100">
                <Card.Img
                  variant="top"
                  src={course.image_url || PlaceholderImage}
                />
                <Card.Body className="d-flex flex-column h-100">
                  <Card.Title as="div" className="mb-1 h4 fw-bold">
                    {course.title}
                  </Card.Title>
                  {course.categories?.length && (
                    <div className="small text-muted d-flex gap-2 mb-3">
                      {course.categories.map((category) => (
                        <Link
                          key={category.id}
                          to={`?q=${category.title}`}
                          className="text-primary"
                        >
                          {category.title}
                        </Link>
                      ))}
                    </div>
                  )}
                  <Card.Text className="mb-5 fs-lg">
                    {course.description}
                  </Card.Text>
                  <Link
                    className="btn btn-secondary-light text-secondary-dark mt-auto align-self-baseline"
                    to={`/course/${course.id}`}
                  >
                    Bekijk cursus
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>
      <Row className="my-5">
        <Col>
          <PaginationCustom />
        </Col>
      </Row>
    </Container>
  );
}

export default CourseList;
