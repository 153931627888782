import React, { useEffect, useState } from "react";

import { ListGroup, Form, ListGroupItem, Alert } from "react-bootstrap";

function CourseOutline({ className, courseId }) {
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the course data from the API
    fetch(`/wp-content/plugins/newlearn-lms/frontend/build/courses.json`)
      .then((response) => response.json())
      .then((data) => {
        const course = data.data.find((course) => course.id === courseId);
        setCourseData(course);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        setLoading(false);
      });
  }, [courseId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!courseData) {
    return <div>Course not found</div>;
  }

  if (!courseData.chapters || !courseData.chapters.length) {
    return <Alert variant="warning">Deze cursus heeft nog geen lessen. Probeer het later opnieuw.</Alert>;
  }

  return (
    <div className={`${className} course-outline`}>
      <div className="border border-2 border-tertiary-light rounded-4">
        <div className="title py-2">Inhoud</div>
        <ListGroup className="chapter pb-2" variant="flush">
          {courseData.chapters.map((chapter) => (
            <React.Fragment key={chapter.id}>
              <ListGroupItem
                className={`chapter-header d-flex align-items-center ${chapter.completed ? "checked" : ""}`}
              >
                <Form.Check type="checkbox" name="hoofdstuk" className="mr-2" disabled checked={chapter.completed} />
                {chapter.title}
              </ListGroupItem>
              {chapter.lessons.map((lesson) => (
                <ListGroupItem key={lesson.id} className="chapter-item d-flex align-items-center">
                  <Form.Check type="checkbox" name="les" className="mr-2" disabled checked={lesson.completed} />
                  {lesson.title}
                </ListGroupItem>
              ))}
            </React.Fragment>
          ))}
        </ListGroup>
      </div>
    </div>
  );
}

export default CourseOutline;
